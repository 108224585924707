import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertComponent } from 'src/app/shared/components/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private _router: Router,
    private _modalService: NgbModal) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
            }
          },
          error => {
            const modalRef = this._modalService.open(AlertComponent, {
              backdrop: 'static',
              keyboard: false,
              centered: true
            });
            if (error.status == 0) {
              modalRef.componentInstance.title = 'Alerta!';
              modalRef.componentInstance.text = 'EL SERVIDOR BACKEND NO RESPONDE';
            } else if (error.status == 400) {
              modalRef.componentInstance.title = 'Alerta!';
              modalRef.componentInstance.text = 'No se puede obtener respuesta del recurso solicitado';
            } else if (error.status == 401) {
              localStorage.clear();
              this._router.navigateByUrl('/raf/check-token');
              modalRef.componentInstance.title = 'Alerta!';
              modalRef.componentInstance.text = 'SU SESIÓN HA EXPIRADO';
            } else if (error.status == 403) {
              localStorage.clear();
              this._router.navigateByUrl('/raf/check-token');
              modalRef.componentInstance.title = 'Alerta!';
              modalRef.componentInstance.text = 'SU SESIÓN HA EXPIRADO';
              window.location.reload();
            } else if (error.status >= 500 && error.status <= 600) {
              modalRef.componentInstance.title = 'Alerta!';
              modalRef.componentInstance.text = 'Error en respuesta del servicio, codigo: ' + error.status;
            } else if (error.status >= 900 && error.status < 1000) {
              modalRef.componentInstance.title = 'Alerta!';
              modalRef.componentInstance.text = 'Error en servicio XXXXX' + error.statusText;
            } else {
              modalRef.componentInstance.title = 'Alerta!';
              modalRef.componentInstance.text = error.error?.data ? error.error.data : error.statusText;
            }
          })
      )
  }
}
