export const environment = {
  // CAMBIAR CON LOS VALORES DE PRODUCTION
  backendservicehost: "https://api-raf.lapositiva.com.pe",
  client_id: '7llg4smoblm35iehkr7o7jtt9v',
  client_secret: 'rf94ss2vg0csu4dqi04t9n8v4eej9jnq57laigdllje9vj3tcng',
  loginUrl: 'https://authprd-raf.auth.us-east-1.amazoncognito.com/oauth2/authorize?' +
  'identity_provider=azure&' +
  'redirect_uri=https://raf.lapositiva.com.pe/raf/callback&' +
  'response_type=code&' +
  'client_id=7llg4smoblm35iehkr7o7jtt9v&'+
  'scope=custodia/admin email openid',
  cognitoTokenURL: 'https://authprd-raf.auth.us-east-1.amazoncognito.com/oauth2/token',
  cognitoRevokeToken: 'https://authprd-raf.auth.us-east-1.amazoncognito.com/oauth2/revoke',
  redirectURL: 'https://raf.lapositiva.com.pe/raf/callback',
  logout: 'https://authprd-raf.auth.us-east-1.amazoncognito.com/logout?' +
    'client_id=7llg4smoblm35iehkr7o7jtt9v&' +
    'response_type=code&' +
    'scope=custodia/admin email openid' +
    '&redirect_uri=https://raf.lapositiva.com.pe/raf/callback',
  production: true
};
